import { css } from 'styled-components';

export const flex = (
    direction = 'row',
    alignItems = 'flex-start',
    justifyContent = 'flex-start'
) => css`
    display: flex;
    flex-direction: ${direction};
    align-items: ${alignItems};
    justify-content: ${justifyContent};
`;