import React from 'react'
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useStateContext } from '../contexts/ContextProvider'

const Submenu = ({ sublinks, visible }) => {

	const { setOfferAnchor, isScrolled, location } = useStateContext()

	return (
		<StyledDropdown
			className='navbar__submenu'
			isVisible={visible}
			isScrolled={isScrolled}
			isHome={location && location.pathname === '/'}
		>
			<StyledDropdownNav>
				<ul>
					{sublinks && sublinks.map((link) => (
						<StyledDropdownLink key={link.name}>
							<NavLink
								to={'offer'}
								key={link.name}
								onClick={() => setOfferAnchor(link.name)}
								className={'navbar__sublink'}
							>
								<span>{link.name}</span>
							</NavLink>
						</StyledDropdownLink>
					))}
				</ul>
			</StyledDropdownNav>
		</StyledDropdown>
	)
}

export default Submenu

const StyledDropdown = styled.div`
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 100%);
	background-color: ${props => (!props.isScrolled && props.isHome) ? 'rgba(20, 20, 20, 0.9)' : '#25242b'};
	backdrop-filter: blur(10px);
	z-index: 1000;
	height: ${props => props.isVisible ? '240px' : 0};
	border-top: 0px solid rgba(90, 90, 90, 0);
	overflow: hidden;
	transition: 0.3s;
	&:hover {
		height: 240px;
		border-top: 3px solid rgba(90, 90, 90, 0.5);
	}
`

const StyledDropdownNav = styled.nav`
	padding: 20px 30px;
`

const StyledDropdownLink = styled.li`
	margin-bottom: 10px;
`