import React from 'react'
import styled from "styled-components";
import { flex } from "../../utils/mixins/flexbox";
import { useStateContext } from '../../contexts/ContextProvider'

// import Button from '../buttons/Button'

const SingleOffer = ({ offer, index }) => {
	const { isMobile } = useStateContext()

	return (
		<StyledSingleOfferWrapper isMobile={isMobile}>
			<StyledSingleOfferColumnLeft
				className={(index % 2 === 0 && !isMobile) ? 'reverse' : ''}
				isMobile={isMobile}
				id={offer.name}
			>
				<h3>{offer.name}</h3>
				<p dangerouslySetInnerHTML={{ __html: offer.text }} />
			</StyledSingleOfferColumnLeft>
			<StyledSingleOfferColumn className="single-offer__column--right" isMobile={isMobile}>
				<picture>
					<img src={offer.background} alt="" />
				</picture>
			</StyledSingleOfferColumn>
		</StyledSingleOfferWrapper>
	)
}

export default SingleOffer

const StyledSingleOfferWrapper = styled.div`
	${flex('row', 'center', 'space-between')};
	flex-direction: ${props => props.isMobile ? 'column' : 'row'};
`

const StyledSingleOfferColumn = styled.div`
	width: ${props => props.isMobile ? 100 : 50}%;
	height: 500px;
	position: relative;
	picture {
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
`
const StyledSingleOfferColumnLeft =  styled(StyledSingleOfferColumn)`
	${flex('column', 'center', 'center')};
	height: ${props => props.isMobile ? 'auto' : '500px'};
	padding: ${props => props.isMobile ? '0 10px' : '80px'};
	order: ${props => props.isMobile ? 2 : 'auto'};
	margin-bottom: ${props => props.isMobile ? '80px' : 0};
	&.reverse {
		order: ${props => props.isMobile ? 1 : 2};
	}
	h3 {
		margin-top: ${props => props.isMobile ? '20px' : 0};
		font-family: "Montserrat-Bold";
		font-size: 35px;
		line-height: 43px;
		margin-bottom: ${props => props.isMobile ? 20 : 40}px;
		text-align: center;
		text-transform: capitalize;
	}
	p {
		font-family: "Montserrat-Medium";
		font-size: 18px;
		line-height: 24px;
	}
`