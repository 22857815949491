import React from 'react'
import { HiArrowNarrowUp } from 'react-icons/hi'
import { HiOutlineChevronDoubleUp } from 'react-icons/hi'

import { useStateContext } from '../../contexts/ContextProvider'

const ScrollTop = () => {

	const { scrollTop } = useStateContext()

	return (
		<button
			className='scroll-top'
			onClick={() => {
				scrollTop()
			}}>
			<HiOutlineChevronDoubleUp />
		</button>
	)
}

export default ScrollTop
