import React from 'react'

import { Hero, TwoColumns} from '../components'

import { useStateContext } from '../contexts/ContextProvider'

const Home = () => {
	const { activeMenu } = useStateContext()

	return (
		<div className={activeMenu ? "home page-with-hero retracted" : "home page-with-hero"}>
			<Hero />
			<TwoColumns />
		</div>
	)
}

export default Home
