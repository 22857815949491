import React from 'react'
import { Link } from 'react-router-dom'
import { useStateContext } from '../../contexts/ContextProvider'

const Button = ({ name, customClass, icon, to, anchor }) => {
	const { setOfferAnchor } = useStateContext()

	return (
		<button>
			<Link
				to={to}
				key={name}
				className={customClass = 'main-button'}
				onClick={() => setOfferAnchor(anchor)}
			>
				{icon}
				<span>{name}</span>
			</Link>
		</button>
	)
}

export default Button
