import React from 'react'
import styled from 'styled-components'
import Button from '../buttons/Button'
import { flex } from "../../utils/mixins/flexbox";
import { phones } from '../../assets'
import { useStateContext } from '../../contexts/ContextProvider'

const TwoColumns = () => {
	const { isMobile } = useStateContext()

	return (
		<StyledTwoColumns isMobile={isMobile} id={'section-1'}>
			<StyledTwoColumnsColumnLeft isMobile={isMobile}>
				<h2>Revolutionize operations<br />with our comprehensive Automation Services</h2>
				<p>Discover the power of streamlined automation through our services.<br />Whether it's simulation, offline/online robot programming, or PLC programming, we've got you covered.<br /><br />Contact us now to explore customized solutions for your business needs.</p>
				<Button
					name='Contact'
					to={'contact'}
				/>
			</StyledTwoColumnsColumnLeft>
			<StyledTwoColumnsColumnRight isMobile={isMobile}>
				<figure>
					<img src={phones} alt="" />
				</figure>
			</StyledTwoColumnsColumnRight>

		</StyledTwoColumns >
	)
}

export default TwoColumns

const StyledTwoColumns = styled.section`
	${flex('row', 'center', 'center')};
	flex-direction: ${props => props.isMobile ? 'column' : 'row'};
	margin: 0 0 100px 0;
	padding: 0 ${props => props.isMobile ? 10 : 120}px;
	padding-top: ${props => props.isMobile ? 80 : 200}px;
`

const StyledTwoColumnsColumnLeft = styled.div`
	${flex('column', 'center', 'center')};
	width: ${props => props.isMobile ? 100 : 60}%;
	padding-left: ${props => props.isMobile ? 0 : 200}px;
	order: ${props => props.isMobile ? 2 : 1};
	h2 {
		font-family: "Montserrat-Bold";
		font-size: 35px;
		line-height: 43px;
		margin-bottom: 40px;
		text-align: ${props => props.isMobile ? 'center' : 'left'};
	}
	p {
		font-family: "Montserrat-Medium";
		font-size: 16px;
		line-height: 20px;
		padding-right: 10px;
		margin-bottom: 33px;
		text-align: ${props => props.isMobile ? 'center' : 'left'};
	}
`

const StyledTwoColumnsColumnRight = styled.div`
	${flex('column', 'center', 'center')};
	order: ${props => props.isMobile ? 1 : 2};
	width: ${props => props.isMobile ? 100 : 40}%;
	figure {
		width: 100%;
	}
	img {
		position: relative;
		left: ${props => props.isMobile ? '-50%' : '-100px'};
	}
`