import React from 'react'
import styled from 'styled-components'
import { flex } from "../utils/mixins/flexbox";
import { AiOutlineInstagram } from 'react-icons/ai'
import { FaFacebookF } from 'react-icons/fa'
import { useStateContext } from '../contexts/ContextProvider'

const Footer = () => {
	const { isMobile } = useStateContext()

	return (
		<>
			{/* <ContactBanner /> */}
			<StyledFooter isMobile={isMobile}>
				<StyledFooterWrapper isMobile={isMobile}>

					<div className="footer__copy">
						<p>&copy;2022 <a href="https://www.linkedin.com/in/martin-chorzewski-ab5935178" target={'_blank'} rel={'noreferrer'}><span className='highlight'> 3DCode </span></a><span>All rights reserved</span></p>
					</div>
					<div className="footer__nav">
						<nav>
							<ul>
								<li><a href="/">About us</a></li>
								<li><a href="/">Privacy policy</a></li>
							</ul>
						</nav>
					</div>
				</StyledFooterWrapper>
			</StyledFooter>
		</>
	)
}

export default Footer

const StyledFooter = styled.footer`
	position: relative;
	bottom: 0;
	width: 100%;
	padding: ${props => props.isMobile ? '0 105px 0 10px' : '0 120px'};
	color: #fff;
	background-color: #25242B;
	p {
		font-family: "Montserrat-Medium";
		font-size: 14px;
		line-height: 16px;
	}
	ul {
		${flex('row', 'center', 'center')};
		column-gap: 20px;
	}
	li {
		position: relative;
		&:nth-child(-n+2) {
			&::after {
				content: '';
				background-color: #000;
				width: 1px;
				height: 80%;
				position: absolute;
				right: -11px;
				top: 50%;
				transform: translateY(-50%)
			}
		}
	}
	a {
		font-family: "Montserrat-Medium";
		font-size: 14px;
		line-height: 16px;
		text-decoration: none;
		color: #fff;
		&:hover {
			color: #5e42dd;
		}
	}
	svg {
		&.fb {
			font-size: 20px;
		}
		&.ig {
			font-size: 24px;
		}
	}
`

const StyledFooterWrapper = styled.div`
	${flex('row', 'center', 'space-between')};
	height: 80px;
	>div {
		span:not(.highlight) {
			font-size: ${props => props.isMobile ? 10 : 14}px;
		}
		width: ${props => props.isMobile ? 45 : 33}%;
		&:last-child {
			display: flex;
			justify-content: flex-end;
		}
	}
`