import React from 'react'
import styled from 'styled-components'
import { flex } from "../../utils/mixins/flexbox";
import { bmw, daimler } from '../../assets'

const Gallery = () => {
  return (
		<StyledPartners>
      <StyledPartnersGallery>
          <StyledPartnersGalleryWrapper className="partners-gallery__wrapper">
            <StyledPartnersGalleryColumnLeft className="partners-gallery__collumn partners-gallery__collumn--left">
              <div className="partners-gallery__heading">
                <h2>We work for<br />the best partners</h2>
                <p>We carry out projects for global brands in the field of: body in white, prototype construction, assembly.</p>
              </div>
            </StyledPartnersGalleryColumnLeft>
            <StyledPartnersGalleryColumnRight className="partners-gallery__collumn partners-gallery__collumn--right">
              <StyledPartnersGalleryTitle className="partners-gallery__tile">
                <picture>
                  <img src={bmw} alt="" />
                </picture>
              </StyledPartnersGalleryTitle>
              <StyledPartnersGalleryTitle className="partners-gallery__tile">
                <picture>
                  <img src={daimler} alt="" />
                </picture>
              </StyledPartnersGalleryTitle>
            </StyledPartnersGalleryColumnRight>
          </StyledPartnersGalleryWrapper>
      </StyledPartnersGallery>
    </StyledPartners>
  )
}

export default Gallery

const StyledPartners = styled.div`
	${flex('row', 'center', 'center')};
  height: calc(100vh - 80px);
  padding-top: 120px;
  h2 {
    font-family: "Montserrat-Bold";
	  font-size: 45px;
	  line-height: 55px;
    margin-bottom: 20px;
    text-align: right;
  }
  p {
    font-family: "Montserrat-Medium";
	  font-size: 18px;
	  line-height: 24px;
    text-align: right;
  }
`

const StyledPartnersGallery = styled.section`
  margin-bottom: 40px;
`

const StyledPartnersGalleryWrapper = styled.div`
	${flex('row', 'center', 'center')};
  flex-wrap: nowrap;
  position: relative;
  gap: 20px;
`

const StyledPartnersGalleryColumn = styled.div`
  width: 50%;
`

const StyledPartnersGalleryColumnLeft = styled(StyledPartnersGalleryColumn)`
  margin: 0 120px;
`

const StyledPartnersGalleryColumnRight = styled(StyledPartnersGalleryColumn)`
  ${flex('column', 'flex-start', 'flex-start')};
  gap: 20px;
  position: relative;
  flex-wrap: wrap;
`

const StyledPartnersGalleryTitle = styled.div`
  padding: 10px;
  picture {
    img {
      max-width: 150px;
    }
  }
`