import React, { useRef } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import { flex } from "../../../utils/mixins/flexbox";
import { slides } from '../../../data/slides'
import { BiRadioCircle } from "react-icons/bi"
import { useStateContext } from '../../../contexts/ContextProvider'
import { BiRadioCircleMarked } from "react-icons/bi"

const SliderNav = (settings, heroSlider) => {

	const { isMobile } = useStateContext()

  	const slidesList = slides
  	const heroSliderNav = useRef()

  	const handleSlide = index => {
    	heroSlider.current.slickGoTo(index)
  	}

	return (
		<div>
		<Slider {...settings} ref={heroSliderNav} >
			{slidesList.map((slide, index) => (
			<StyledSliderNavButton
				key={`${slide.name}`}
				onClick={() => { handleSlide(index) }}
				style={{ backgroundImage: `url(${slide.background})` }}
				isMobile={isMobile}
				isActive={slide.active}
			>
				<StyledSliderNavContent>
				<StyledSliderNavNavigator className={`hero__slider-nav--navigator ${slide.active ? "active" : null}`}>
					{
					slide.active ? <BiRadioCircleMarked /> : <BiRadioCircle />
					}
					<p>{slide.name.toUpperCase()}</p>
				</StyledSliderNavNavigator>
				</StyledSliderNavContent>
			</StyledSliderNavButton>
			))}
		</Slider>
		</div>
	)
}

export default SliderNav


const StyledSliderNavButton = styled.div`
 	${flex('row', 'center', 'center')};
	position: relative;
	display: ${props => props.isMobile ? props.isActive ? 'flex' : 'none' : 'flex'};
	height: 100%;
	cursor: pointer;
	&:hover {
		p {
			@include MS-Bold-22-30;
		}
		svg {
			font-size: 70px;
		}
	}
`

const StyledSliderNavContent = styled.div`
  ${flex('row', 'center', 'center')};
	z-index: 20;
	height: 100%;
  position: relative;
	p {
		margin-bottom: 0;
		text-transform: capitalize;
	}
`

const StyledSliderNavNavigator = styled.div`
  ${flex('column', 'center', 'center')};
	width: 100%;
	height: 100%;
	border-top: none;
	border-top: 3px solid rgba(90, 90, 90, 0.5);
	&.active {
		border-top: 4px solid #5e42dd;
		box-shadow: rgba(#5e42dd, 0.25) 0px -10px 40px -12px inset, rgba($complementary-color, 0.3) 0px 18px 10px -15px inset;
		svg {
			color: #5e42dd;
		}
		p {
      font-family: "Montserrat-Bold";
      font-size: 16px;
      line-height: 20px;
			color: #5e42dd;
		}
	}
	p {
    font-family: "Montserrat-Regular";
	  font-size: 20px;
	  line-height: 30px;
		letter-spacing: 1px;
		transition: 0.3s;
		color: #fff;
	}
	picture {
    ${flex('row', 'center', 'center')};
		position: relative;
	}
	svg {
		color: #fff;
		font-size: 60px;
		transition: 0.4s;
	}
`