import React from 'react'
import styled from "styled-components";
import { flex } from "../utils/mixins/flexbox";
import { buildings } from '../assets'
import { ContactForm } from '../components'
import { useStateContext } from '../contexts/ContextProvider'

const ContactUs = () => {
	const { isMobile } = useStateContext()

	return (
		<StyledContactUs isMobile={isMobile} style={{ backgroundImage: `url(${buildings})` }}>
			<ContactForm />
		</StyledContactUs>
	)
}

export default ContactUs

const StyledContactUs = styled.div`
	${flex('row', 'center', 'center')};
	padding-top: 150px;
	height: ${props => props.isMobile ? 'auto' : 'calc(100vh - 80px)'};
	background-size: cover;
	background-repeat: no-repeat;
	h2 {
		color: #000;
	}
`