export const links = [
	{
		title: 'Pages',
		links: [
			{
				name: 'about',
				active: false,
			},
			// {
			// 	name: 'news',
			// 	active: false,
			// },
			{
				name: 'offer',
				active: false,
				submenu:
					[
						{
							name: 'automation',
						},
						{
							name: 'robotics',
						},
						{
							name: 'electrics',
						},
						{
							name: 'mechanics',
						},
						{
							name: 'simulations',
						},
					],
			},
			{
				name: 'partners',
				active: false,
			},
			{
				name: 'contact',
				active: false,
			},
		],
	},
];
