import React from 'react'
import styled from "styled-components";
import { flex } from "../../utils/mixins/flexbox";
import { useStateContext } from '../../contexts/ContextProvider'

const AboutSingle = ({ name, text, image, imageBackground, reversed }) => {
	const { isMobile } = useStateContext()

	return (
		<StyledAboutSingle isMobile={isMobile}>
			<StyledAboutWrapper isMobile={isMobile}>
				<StyledAboutColumnLeft className={`${reversed ? 'reversed' : ''}`} isMobile={isMobile}>
					<StyledAboutImgWrapper isMobile={isMobile}>
						<picture>
							<StyledImage isMobile={isMobile} src={image} alt="" />
						</picture>
					</StyledAboutImgWrapper>
				</StyledAboutColumnLeft>
				<StyledAboutColumnRight className={`${reversed ? 'reversed' : ''}`} isMobile={isMobile}>
					<div>
						<StyledAboutHeader>{name}</StyledAboutHeader>
						<StyledParagraph dangerouslySetInnerHTML={text}></StyledParagraph>
					</div>
				</StyledAboutColumnRight>
			</StyledAboutWrapper>
		</StyledAboutSingle>
	)
}

export default AboutSingle

const StyledAboutSingle = styled.section`
	position: relative;
	margin-bottom: 80px;
	padding: 0 ${props => props.isMobile ? 10 : 120}px;
`

const StyledAboutWrapper = styled.div`
	${flex('row', 'center', 'space-between')};
	flex-direction: ${props => props.isMobile ? 'column' : 'row'};
	position: relative;
`

const StyledAboutHeader = styled.h3`
	font-family: "Montserrat-Bold";
	font-size: 35px;
	line-height: 43px;
	margin-bottom: 40px;
`

const StyledParagraph = styled.p`
	font-family: "Montserrat-Medium";
	font-size: 18px;
	line-height: 24px;
`

const StyledAboutColumn = styled.div`
	position: relative;
	width: ${props => props.isMobile ? 100 : 50}%;
`

const StyledAboutColumnLeft = styled(StyledAboutColumn)`
	${flex('column', 'center', 'center')};
	&.reversed {
		order: ${props => props.isMobile ? 'unset' : 2};
	}
`

const StyledImage = styled.img`
	object-fit: cover;
	height: 600px;
	width: 100%;
`

const StyledAboutColumnRight = styled(StyledAboutColumn)`
	${flex('column', 'center', 'center')};
	position: relative;
	padding: 40px;
	padding-top: ${props => props.isMobile ? 0 : 40}px;
	&.reversed {
		align-items: flex-end;
		justify-content: flex-start;
	}
`

const StyledAboutImgWrapper = styled.div`
	padding: 40px 0;
	background-size: cover;
	width: ${props => props.isMobile ? '100%' : 'max-content'};
	picture {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
	}
`