import { TS1, brobg1, bro2, brobg2, grol, kraj } from "../assets"

export const people = [
	{
		name: 'Michał Grol',
		photo: grol,
		background: brobg1,
		text: "Servus!<br />I'm Michal and currently based in Regensburg, Germany. I graduated Mechatronics and Automatic Controls and Robotics at the Silesian University of Technology in Gliwice, Poland. Starting in 2017 as an intern in the production maintenance, then in virtual commissioning up to PLC programmer. Since 2022 as a Leader of Operation I was responsible for on-site commissioning and project management. Using my several years of experience I would like to combine effectiveness and performance to improve processes. Passionate about astronomy and cars. Like travelling and doing sports.",
		social: [
			{
				name: 'fb',
				icon: '',
				url: '',
			},
		],
		reversed: false,
	},
	{
		name: 'Arkadiusz Kałużny',
		photo: TS1,
		background: brobg2,
		text: "Hi!<br />I'm Arek. Graduated from Maritime Academy of Szczecin. In Automotive since 2014. Starting as simulation engineer, then industrial robots programmer, team leader up to site manager recently. I see many automotive companies working inefficient. My goal is to optimize simulation and commissioning process to develop high quality output without wasting client time and money. Like spending my free time hiking with my wife and our dog. Sport enthusiast.",
		social: [
			{
				name: 'fb',
				icon: '',
				url: '',
			},
		],
		reversed: true,
	},
	{
		name: 'Jakub Kraj',
		photo: kraj,
		background: brobg1,
		text: "Hi!<br />I entered the automotive industry in 2015. My journey began as an online robot programmer, and by 2018, I was promoted to the role of Robotics Division Leader. Since then, I have been at the helm of robotic teams, overseeing diverse projects for various clients. My leadership extended to managing entire projects for prestigious companies like BMW and Daimler on a global scale. Passionate about technology and a devoted petrolhead, I thrive in the dynamic intersection of robotics and automotive innovation.",
		social: [
			{
				name: 'fb',
				icon: '',
				url: '',
			},
		],
		reversed: false,
	},
];
