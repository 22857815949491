import React from 'react'
import styled from 'styled-components'
import { people } from '../data/people'
import { useStateContext } from '../contexts/ContextProvider'
import { AboutHeader, AboutSingle } from '../components'

const AboutUs = () => {
	const { isMobile } = useStateContext()

	return (
		<StyledAbout className='about-us' isMobile={isMobile}>
			<AboutHeader />

			{people && people.map(person => (
				<AboutSingle
					key={person.name}
					name={person.name}
					text={{ __html: person.text }}
					reversed={person.reversed}
					image={person.photo}
					imageBackground={person.background} />
			))}
		</StyledAbout>
	)
}

export default AboutUs

const StyledAbout = styled.div`
	padding-top: ${props => props.isMobile ? 120 : 230}px;
`