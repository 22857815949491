import { pagetitle } from "../assets"
import { automation3 } from "../assets"
import { robotics } from "../assets"
import { electrics } from "../assets"
import { mechanics } from "../assets"
import { simulation } from "../assets"


export const slides = [

	{
		name: 'CoMAK',
		heading: `CoMAK`,
		subheading: 'Our company specializes in industrial automation services, with a focus on simulation, robot programming, and PLC programming.',
		background: pagetitle,
		active: false
	},
	{
		name: 'automation',
		prefix: 'CoMAK',
		heading: `Automation`,
		subheading: 'We excel in PLC programming, offering expertise both offline and online.',
		background: automation3,
		active: false,
	},
	{
		name: 'robotics',
		prefix: 'CoMAK',
		heading: `Robotics`,
		subheading: 'We streamline the creation and optimization of robot programs, ensuring a seamless process without disrupting ongoing production.',
		background: robotics,
		active: false
	},
	{
		name: 'simulations',
		prefix: 'CoMAK',
		heading: `Simulations`,
		subheading: 'We specialize in advanced robotic simulations, creating virtual environments for precise testing of robots functions, including motion planning and collision detection.',
		background: simulation,
		active: false
	},
	{
		name: 'electrics',
		prefix: 'CoMAK',
		heading: `Electrics`,
		subheading: 'Supercharge your electrical systems with our adept team of specialized electricians. Experience excellence as we bring precision and reliability to optimize the performance of your electrical infrastructure.',
		background: electrics,
		active: false
	},
	{
		name: 'mechanics',
		prefix: 'CoMAK',
		heading: `Mechanics`,
		subheading: 'Elevate your operations with our expert mechanics. Specialized, experienced, and dedicated to optimizing your machinery for peak performance.',
		background: mechanics,
		active: false
	},

];
