import React, {useState} from "react"
import styled from "styled-components";
import Submenu from "./Submenu";
import { AiOutlineMenu } from "react-icons/ai"
import { Link, NavLink } from "react-router-dom";
import { flex } from "../utils/mixins/flexbox";
import { useStateContext } from '../contexts/ContextProvider'
import { links } from '../data/links'
import { comakLogo } from '../assets'

const Navbar = () => {

	const { setActiveMenu, isMobile, isActive, isScrolled } = useStateContext()

	const [dropdownVisible, setDropdownVisible] = useState(false);
	const activeLink = 'active'

	return (
		<StyledNavBar className={isMobile ? 'navbar mobile' : 'navbar'} isMobile={isMobile}>
			{isMobile && (
				<StyledNavBarMobile>
					<Link to="/">
						<StyledNavLogoMobile src={comakLogo} alt="" />
					</Link>
					<StyledNavButton type="button" onClick={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}>
						<span className="navbar__notificationDot" />
						<AiOutlineMenu />
					</StyledNavButton>
				</StyledNavBarMobile>
			)}
			<Link to="/">
				<StyledNavLogo src={comakLogo} alt="" hidden={isMobile} isScrolled={isScrolled} />
			</Link>
			<StyledNav isHidden={isMobile}>
				{links && links.map((item) => (
					<StyledLinks key={item.title} className="navbar__links--wrapper">
						{item.links && item.links.map((link, _index) => (
							<StyledLink key={link.name}>
								<NavLink
									to={`${link.name}`}
									key={link.name}
									className={`${isActive ? activeLink : ''}`}
									onMouseEnter={() => setDropdownVisible(link.submenu ? true : false)}
									onMouseLeave={() => setDropdownVisible(false)}
								>
									<span>{link.name}</span>
								</NavLink>
								{link.submenu && (
									<Submenu
										sublinks={link.submenu}
										visible={dropdownVisible}
									/>
								)}
							</StyledLink>
						))}
					</StyledLinks>
				))}
			</StyledNav>
		</StyledNavBar >
	)
}

const StyledNavBar = styled.div`
	${flex('row', 'center', 'space-between')};
	justify-content: ${props => props.isMobile ? 'flex-end' : 'space-between'};
	gap: ${props => props.isMobile ? 0 : 15}px;
	width: 100%;
	height: 100%;
`

const StyledNavBarMobile = styled.div`
	${flex('row', 'center', 'space-between')};
	width: 100%;
	padding: 0 15px;
`

const StyledNav = styled.nav`
	${flex('row', 'center', 'flex-start')};
	height: 100%;
	display: ${props => props.isHidden ? 'none' : 'flex'};
`

const StyledNavLogo = styled.img`
	margin-right: 130px;
	transition: .4s;
	width: ${props => props.isScrolled ? 130 : 200}px;
	display: ${props => props.hidden ? 'none' : 'block'};
`

const StyledNavLogoMobile = styled.img`
	/* margin-right: 130px; */
	transition: .4s;
	width: 100px;
`

const StyledNavButton = styled.button`
	font-size: 24px;
	svg {
		fill: #fff;
	}
`

const StyledLinks = styled.ul`
	${flex('row', 'center', 'space-between')};
	flex-wrap: nowrap;
	column-gap: 40px;
	width: 100%;
	height: inherit;
	svg {
		font-size: 22px;
		margin-left: 8px;
		path {
			stroke: $white;
		}
	}
`

const StyledLink = styled.li`
	${flex('row', 'center', 'center')};
	position: relative;
	height: inherit;
`

export default Navbar
