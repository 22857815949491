import { pagetitle, robotics, electrics, mechanics3, simulation } from "../assets"

export const offers = [
	{
		name: 'automation',
		background: pagetitle,
		text: 'We excel in PLC programming, offering expertise both offline and online. In offline programming, we refine logic seamlessly in a virtual environment, minimizing disruptions. Online, we configure and optimize PLCs in real-time, ensuring adaptability to evolving production needs. Our focus is on precision and reliability, tailored to enhance automation processes and improve overall efficiency.',
		button: {
			name: 'READ MORE'
		}
	},
	{
		name: 'robotics',
		background: robotics,
		text: 'We streamline the creation and optimization of robot programs, ensuring a seamless process without disrupting ongoing production. This is achieved through the development of offline programs, which seamlessly integrate with PLC systems. This integration allows us to coordinate and control various elements of the production process, ensuring smooth communication and synchronization among diverse automation components. Our testing and debugging procedures are conducted online, verifying the accurate and secure execution of robot tasks. We prioritize managing cycle time and ensuring high process quality throughout this comprehensive approach.',
		button: {
			name: 'READ MORE'
		}
	},
	{
		name: 'electrics',
		background: electrics,
		text: 'Supercharge your electrical systems with our adept team of specialized electricians. Experience excellence as we bring precision and reliability to optimize the performance of your electrical infrastructure.',
		button: {
			name: 'READ MORE'
		}
	},
	{
		name: 'mechanics',
		background: mechanics3,
		text: 'Elevate your operations with our expert mechanics. Specialized, experienced, and dedicated to optimizing your machinery for peak performance',
		button: {
			name: 'READ MORE'
		}
	},
	{
		name: 'simulations',
		background: simulation,
		text: 'We specialize in advanced robotic simulations, creating virtual environments for precise testing of robots functions, including motion planning and collision detection. Our simulations, featuring detailed 3D models and sensor integrations, ensure comprehensive evaluations and versatile scenario testing. This efficient process aids in early issue identification, contributing to cost and time savings, and provides a solid foundation for seamless integration of robotic systems into operational workflows.',
		button: {
			name: 'READ MORE'
		}
	},
];
