import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from '../buttons/Button'
import Slider from 'react-slick'
import SlideDownButton from '../buttons/SlideDownButton'
import { flex } from "../../utils/mixins/flexbox";
import { slides } from '../../data/slides'
import { BiRadioCircle } from "react-icons/bi"
import { useStateContext } from '../../contexts/ContextProvider'
import { BiRadioCircleMarked } from "react-icons/bi"

export const Hero = () => {

	const { replaceWithBr, isMobile } = useStateContext()

	const slidesToShow = slides.length

	const heroSlider = useRef()
	const heroSliderNav = useRef()

	const [nav1, setNav1] = useState(null)
	const [nav2, setNav2] = useState(null)

	const [slidesList, setSlidesList] = useState(null)

	const handleSlideSwipe = (next) => {
		handleToggleActive(next)
	}

	const settings = {
		dots: false,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnHover: false,
		asNavFor: nav2,
		beforeChange: (current, next) => { handleSlideSwipe(next) },
	}

	const settingsNav = {
		dots: false,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: slidesToShow,
		slidesToScroll: 1,
		autoplay: false,
		asNavFor: nav1,
		centerMode: false,
		focusOnSelect: true
	}

	useEffect(() => {
		setNav1(heroSlider.current)
		setNav2(heroSliderNav.current)
	}, [heroSlider, heroSliderNav])

	useEffect(() => {
		handleToggleActive(0)
	}, [])

	const handleSlide = index => {
		heroSlider.current.slickGoTo(index)
	}

	const handleToggleActive = index => {

		for (const slide of slides) {
			slide.active = false
		}

		slides[index].active = true

		const tempSlidesList = slides.map((slide, index) => (
			<StyledSliderNavButton
				key={`${slide.name}`}
				onClick={() => { handleSlide(index); handleToggleActive(index) }}
				style={{ backgroundImage: `url(${slide.background})` }}
				isMobile={isMobile}
				isActive={slide.active}
			>
				<StyledSliderNavContent>
					<StyledSliderNavNavigator className={`${slide.active && 'active'}`}>
						{slide.active ? <BiRadioCircleMarked /> : <BiRadioCircle />}
						<p>{slide.name}</p>
					</StyledSliderNavNavigator>
				</StyledSliderNavContent>
			</StyledSliderNavButton>
		))

		setSlidesList(tempSlidesList)
	}

	return (
		<StyledHero className='hero full-width'>
			<Slider {...settings} ref={heroSlider} >
				{slides && slides.map((slide) => (
					<StyledHeroSlide key={slide.name}>
						<StyledHeroWrapper
							className={`hero__wrapper`}
							style={{ backgroundImage: `url(${slide.background})` }}>
							<StyledHeroContent isMobile={isMobile}>
								{slide.prefix &&
									<StyledHeroContentPrefix  dangerouslySetInnerHTML={{ __html: replaceWithBr(slide.prefix) }} />}
								{slide.heading &&
									<StyledHeroContentHeading
										dangerouslySetInnerHTML={{ __html: replaceWithBr(slide.heading) }}
										isMobile={isMobile}
									/>
								}
								{slide.subheading &&
									<StyledHeroContentSubeading
										dangerouslySetInnerHTML={{ __html: replaceWithBr(slide.subheading) }}
										isMobile={isMobile}
									/>}
								<StyledHeroContentButtons isMobile={isMobile}>
									<SlideDownButton
										name={'READ MORE'}
										anchor='section-1'
									/>
									{slide.name !== 'CoMAK' &&
										<Button
											to={`offer`}
											name={'VIEW OFFER'}
											anchor={slide.name}
										/>
									}
								</StyledHeroContentButtons>
							</StyledHeroContent>
						</StyledHeroWrapper>
					</StyledHeroSlide>
				))}
			</Slider>
			{!isMobile && (
				<StyledSliderNav>
					<Slider {...settingsNav} ref={heroSliderNav} >
						{slidesList}
					</Slider>
				</StyledSliderNav>
			)}
		</StyledHero >
	)
}

export default Hero

const StyledHero = styled.section`
	height: 100vh;
	border-radius: 30px;
	position: relative;
	figure {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.slick-slider,
	.slick-list,
	.slick-track,
	.slick-slide>div,
	.single-slide {
		height: inherit;
	}
`

const StyledHeroSlide = styled.div`
	height: inherit;
`

const StyledHeroWrapper = styled.div`
	${flex('column', 'center', 'center')};
	position: relative;
	height: 100%;
	z-index: 0;
	background-repeat: no-repeat;
	background-size: cover;
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		position: absolute;
		top: 0;
		left: 0;
	}
`

const StyledHeroContent = styled.div`
	${flex('column', 'center', 'center')};
	position: relative;
	width: ${props => props.isMobile ? 80 : 50}%;
	z-index: 1;
	h1,
	p {
		color: #fff;
	}
`

const StyledHeroContentPrefix = styled.p`
	font-family: "Montserrat-Regular";
	font-size: 20px;
	line-height: 30px;
	text-align: left;
`

const StyledHeroContentHeading = styled.h1`
	font-family: "Montserrat-Bold";
	font-size: ${props => props.isMobile ? 50 : 70}px;
	line-height: 80px;
	text-align: center;
	margin-bottom: ${props => props.isMobile ? 15 : 40}px;
`

const StyledHeroContentSubeading = styled.p`
	font-family: "Montserrat-Regular";
	font-size: ${props => props.isMobile ? 20 : 26}px;
	line-height: 30px;
	margin-bottom: 40px;
	text-align: center;
`

const StyledHeroContentButtons = styled.div`
	${flex('row', 'center', 'space-between')};
	flex-direction: ${props => props.isMobile ? 'column' : 'row'};
	gap: 20px;
`

const StyledSliderNav = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 150px;
	z-index: 2;
	backdrop-filter: blur(5px) grayscale(80%);
	background-color: rgba(0, 0, 0, 0.4);
`

const StyledSliderNavButton = styled.div`
 	${flex('row', 'center', 'center')};
	position: relative;
	display: ${props => props.isMobile ? props.isActive ? 'flex' : 'none' : 'flex'};
	height: 100%;
	cursor: pointer;
	&:hover {
		p {
			font-family: "Montserrat-Bold";
			font-size: 22px;
			line-height: 30px;
		}
		svg {
			font-size: 70px;
		}
	}
`

const StyledSliderNavContent = styled.div`
  	${flex('row', 'center', 'center')};
	z-index: 20;
	height: 100%;
  	position: relative;
	p {
		margin-bottom: 0;
		text-transform: capitalize;
	}
`

const StyledSliderNavNavigator = styled.div`
  ${flex('column', 'center', 'center')};
	width: 100%;
	height: 100%;
	border-top: none;
	border-top: 3px solid rgba(90, 90, 90, 0.5);
	&.active {
		border-top: 4px solid #5e42dd;
		box-shadow: rgba(#5e42dd, 0.25) 0px -10px 40px -12px inset, rgba(#402962, 0.3) 0px 18px 10px -15px inset;
		svg {
			color: #5e42dd;
		}
		p {
			font-family: "Montserrat-Bold";
			font-size: 22px;
			line-height: 30px;
			color: #5e42dd;
		}
	}
	p {
    	font-family: "Montserrat-Regular";
	  	font-size: 20px;
	  	line-height: 30px;
		letter-spacing: 1px;
		transition: 0.3s;
		color: #fff;
	}
	picture {
    	${flex('row', 'center', 'center')};
		position: relative;
	}
	svg {
		color: #fff;
		font-size: 60px;
		transition: 0.4s;
	}
`