import React, { useState } from 'react'
import styled from "styled-components";
import { flex } from "../../utils/mixins/flexbox";
import { PiSuitcase  } from "react-icons/pi";
import { BsTelephone } from "react-icons/bs"
import { FaLinkedinIn } from 'react-icons/fa'
import { GrMapLocation } from "react-icons/gr"
import { useStateContext } from '../../contexts/ContextProvider'
import { MdAlternateEmail } from "react-icons/md"

const ContactForm = () => {
	const { isMobile } = useStateContext()
	const [formData, setFormData] = useState({
        name: '',
        last_name: '',
        email: '',
        phone: '',
        message: ''
    });
	const [submitSuccess, setSubmitSuccess] = useState(null);
	const [submitError, setSubmitError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/php/mail_redirect.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                setSubmitSuccess('Email sent successfully!');
            } else {
                setSubmitError('Error sending email.');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

	return (
		<StyledContactFormWrapper className='contact-form' isMobile={isMobile}>
			<StyledContactWrapper>
				<ContactHeader>Send a message</ContactHeader>
				<StyledContactContent isMobile={isMobile}>
					<StyledContactInfo isMobile={isMobile}>
						<StyledContactInfoWrapper>
							<h3>Contact details<br />CoMAK Sp. z o.o.</h3>
							<div>
								<GrMapLocation />
								<p>Ul. Gen. Sikorskiego 7/4a 66-200 Świebodzin</p>
							</div>
							<div>
								<MdAlternateEmail />
								<p><a href="mailto:office@comak.eu">office@comak.eu</a></p>
							</div>
							<div>
								<BsTelephone />
								<p>	<a href="tel:+48 728 939 878">+48 728 939 878</a></p>
							</div>
							<div>
								<BsTelephone />
								<p>	<a href="tel:+49 173 1713211">+49 173 1713211</a></p>
							</div>
							<div>
								<PiSuitcase />
								<p>NIP: 9271971631</p>
							</div>
							<div>
								<FaLinkedinIn />
								<p><a href="/">LinkedIn</a></p>
							</div>
						</StyledContactInfoWrapper>

						{/* <div className="contact-form__info--social">
							<FaLinkedinIn />
						</div> */}

					</StyledContactInfo>

					{/* <StyledContactForm isMobile={isMobile} action="/php/mail_redirect.php" method="post" id="contact-form"> */}
					<StyledContactForm isMobile={isMobile} onSubmit={handleSubmit} id="contact-form">
						<ContactFormLabel htmlFor="name" isMobile={isMobile}>First name
							<ContactFormInput type="text" id='name' name="name" onChange={handleChange} />
						</ContactFormLabel>
						<ContactFormLabel htmlFor="last_name" isMobile={isMobile}>Last name
							<ContactFormInput type="text" id='last_name' name="last_name" onChange={handleChange} />
						</ContactFormLabel>
						<ContactFormLabel htmlFor="email" isMobile={isMobile}>E-mail
							<ContactFormInput type="email" id="email" name="email" onChange={handleChange} />
						</ContactFormLabel>
						<ContactFormLabel htmlFor="phone" isMobile={isMobile}>Phone
							<ContactFormInput type="tel" id="phone" name="phone" onChange={handleChange} />
						</ContactFormLabel>
						<ContactFormLabel htmlFor="message" className='label-full' isMobile={isMobile}>Message
							<StyledFormTextarea name="message" id="message" form="contact-form" onChange={handleChange} />
						</ContactFormLabel>
						<ContactFormSend type="submit" value="Send"></ContactFormSend>
						<p>{submitSuccess 
							? (
								<StyledSubmitSuccess>{submitSuccess}</StyledSubmitSuccess>
									) : submitError ? (
										<StyledSubmitError>{submitError}</StyledSubmitError>
							) : null
						}</p>
					</StyledContactForm>
				</StyledContactContent>
			</StyledContactWrapper>
		</StyledContactFormWrapper>
	)
}

export default ContactForm

const StyledContactFormWrapper = styled.section`
	padding: 0 ${props => props.isMobile ? 0 : '120px'};
`

const StyledContactWrapper = styled.div`
	max-width: 1200px;
`

const StyledContactContent = styled.div`
	${flex('row', 'flex-start', 'flex-start')};
	display: flex;
	flex-direction: ${props => props.isMobile ? 'column' : 'row'};
	margin-left: ${props => props.isMobile ? 0 : '150px'};
	background-color: rgba(255, 255, 255, 0.96);
	background-size: cover;
	backdrop-filter: blur(5px);
	box-shadow: 5px 5px 20px 2px rgba(0, 0, 0, 0.5), 10px 10px 30px 2px rgba(0, 0, 0, 0.1);
	padding: 60px 0;
	margin-bottom: ${props => props.isMobile ? 0 : '61px'};
`

const StyledContactInfo = styled.div`
	${flex('column', 'flex-start', 'space-between')};
	padding: 40px;
	background-color: #402962;
	box-shadow: ${props => props.isMobile ? 'unset' : '5px 5px 20px 2px rgba(0, 0, 0, 0.5), 10px 10px 5px 2px rgba(0, 0, 0, 0.1)'};
	transform: ${props => props.isMobile ? 'unset' : 'translateX(-50%)'};
	h3 {
		font-family: "Montserrat-Bold";
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 25px;
		color: #fff;
	}
	p {
		font-family: "Montserrat-Medium";
		font-size: 16px;
		line-height: 20px;
		color: #fff;
	}
	a {
		color: #fff;
	}
`

const StyledContactInfoWrapper = styled.div`
	${flex('column', 'flex-start', 'flex-start')};
	row-gap: 10px;
	>div {
		${flex('row', 'center', 'flex-start')};
		column-gap: 12px;
		svg {
			flex-shrink: 0;
			font-size: 25px;
			color: #fff;
			path {
				color: #fff;
				stroke: #fff;
			}
		}
	}
`

const StyledContactForm = styled.form`
	${flex('row', 'center', 'space-between')};
	flex-wrap: wrap;
	gap: 20px;
	position: relative;
	left: ${props => props.isMobile ? 0 : '-75px'};
	padding: ${props => props.isMobile ? '10px' : 0};
	padding-top: ${props => props.isMobile ? '40px' : 0};
	input[data-autocompleted] {
		background-color: transparent !important;
	}
	input:-webkit-autofill,
	input:-webkit-autofill:focus {
		transition: background-color 600000s 0s, color 600000s 0s;
	}
`

const ContactFormLabel = styled.label`
	display: flex;
	flex-direction: column;
	width: ${props => props.isMobile ? '100%' : 'calc(50% - 20px)'};
	color: #000;
	font-family: "Montserrat-Medium";
	font-size: 14px;
	line-height: 16px;
	&.label-full {
		width: 100%;
		margin-top: 8px;
	}
`
const ContactFormInput = styled.input`
	margin-top: 8px;
	background-color: transparent;
	border: none;
	padding: 8px 0;
	border-bottom: 1px solid black;
	font-family: "Montserrat-Medium";
	font-size: 18px;
	line-height: 24px;
	&:focus {
		outline: none;
	}
`

const ContactFormSend = styled.input`
	text-transform: uppercase;
	padding: 8px 20px;
	background-color: #5e42dd;
	border-radius: 8px;
	color: #fff;
	border: 2px solid #5e42dd;
	cursor: pointer;
	transition: 0.3s;
	font-family: "Montserrat-Medium";
	font-size: 16px;
	line-height: 24px;
`

const StyledFormTextarea = styled.textarea`
	padding: 8px;
	resize: none;
	height: 150px;
	margin-top: 8px;
	background-color: transparent;
	font-family: "Montserrat-Medium";
	font-size: 16px;
	line-height: 20px;
	&:focus {
		outline: none;
	}
`

const ContactHeader = styled.h2`
	font-family: "Montserrat-Bold";
	font-size: 35px;
	line-height: 43px;
	text-align: center;
	margin-bottom: 40px;
`

const StyledSubmitSuccess = styled.span`
	color: #82dd55;
`

const StyledSubmitError = styled.span`
	color: #ed4337;
`