import React, { useEffect } from 'react'
import styled from "styled-components";
import { offers } from '../data/offers'
import { SingleOffer } from '../components'
import { useStateContext } from '../contexts/ContextProvider'

const Offer = () => {
	const { isMobile, offerAnchor, setOfferAnchor } = useStateContext()

	useEffect(() => {
		if (offerAnchor) {
			const anchor = document.getElementById(offerAnchor)
			if (anchor) {
				window.scrollTo({
					top: anchor.offsetTop -80,
					behavior: "smooth"
				});
			}
			setTimeout(() => setOfferAnchor(null), 400);
			
		}
	}, [])

	return (
		<StyledOffer isMobile={isMobile}>
			<h2>Discover our offers</h2>
			{offers && offers.map(( offer, index ) => (
				<section className={`full-width`} key={offer.name} isMobile={isMobile}>
					<SingleOffer offer={offer} index={index} />
				</section>
			))
			}
		</StyledOffer >
	)
}

export default Offer

const StyledOffer = styled.div`
	padding-top: ${props => props.isMobile ? 120 : 230}px;
	h2 {
		font-family: "Montserrat-Bold";
		font-size: 35px;
		line-height: 43px;
		text-align: center;
		margin-bottom: 80px;
		text-transform: capitalize;
	}
`
