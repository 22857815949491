import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { useStateContext } from './contexts/ContextProvider'

import { Header, Sidebar, Footer, ScrollTop } from './components'
import { Home, AboutUs, News, Offer, Partners, ContactUs, Automation, Robotics, Electrics, Mechanics, Simulations } from './pages'

import './App.scss'
import './index.scss'


const App = () => {

	const { activeMenu, setLocation, isScrolled } = useStateContext()

	const routePath = useLocation()

	useEffect(() => {
		setLocation(routePath)
	}, [routePath])

	return (
		<div className="App">
			<div className="main__wrapper">

				<Header />
				<div>
					{/* {activeMenu ? (
						<Sidebar />
					) : (
						<div className="sidebar hidden">
							<Sidebar />
						</div>
					)} */}
					<Sidebar />
				</div>

				<div>
					<Routes>
						{/* Home Page */}
						<Route path="/" element={<Home />} />

						{/* Pages */}
						<Route path="/about" element={<AboutUs />} />
						<Route path="/news" element={<News />} />
						<Route path="/offer" element={<Offer />} />
						<Route path="/partners" element={<Partners />} />
						<Route path="/contact" element={<ContactUs />} />
						<Route path="/offer/automation" element={<Automation />} />
						<Route path="/offer/robotics" element={<Robotics />} />
						<Route path="/offer/electrics" element={<Electrics />} />
						<Route path="/offer/mechanics" element={<Mechanics />} />
						<Route path="/offer/simulations" element={<Simulations />} />

					</Routes>
				</div>

				{isScrolled && <ScrollTop />}
				<Footer />
			</div>
		</div >
	)
}
export default App
