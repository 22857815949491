import React from 'react'
import styled from "styled-components";
import { flex } from "../utils/mixins/flexbox";
import { useStateContext } from '../contexts/ContextProvider'
import Navbar from './Navbar'


const Header = () => {

	const { isScrolled, isMobile, location } = useStateContext()

	return (
		<StyledMainHeader isScrolled={isScrolled} isMobile={isMobile} isHome={location && location.pathname === '/'}>
			<Navbar />
		</StyledMainHeader>
	)
}

export default Header

const StyledMainHeader = styled.header`
	${flex('row', 'center', 'space-between')};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: ${props => props.isScrolled ? 80 : props.isMobile ? 80 : 150}px;
	padding: 0 ${props => props.isMobile ? 10 : 180}px;
	background-color:  ${props => (!props.isScrolled && props.isHome) ? 'rgba(0, 0, 0, 0.3)' : '#25242b'};
	backdrop-filter: blur(10px);
	border-bottom: 3px solid rgba(90, 90, 90, 0.5);
	z-index: 10;
	transition: 0.3s;
`