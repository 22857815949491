import React, { createContext, useContext, useEffect, useState } from "react"

const StateContext = createContext({})

export const ContextProvider = ({ children }) => {

	const [activeMenu, setActiveMenu] = useState(false)

	const [isActive, setIsActive] = useState(undefined)

	const [screenSize, setScreenSize] = useState(undefined)

	const [isMobile, setIsMobile] = useState(window.innerWidth <= 900)

	const [scrollY, setScrollY] = useState(undefined)

	const [isScrolled, setIsScrolled] = useState(false)

	const [location, setLocation] = useState(undefined)

	const [offerAnchor, setOfferAnchor] = useState(null)

	const replaceWithBr = string => {
		return string.replace(/\n/g, "<br />")
	}

	const scrollTop = () => {
		window.scrollTo(0, 0)
	}

	useEffect(() => {
		const handleResize = () => {
			setScreenSize(window.innerWidth)
		}
		window.addEventListener('resize', handleResize)

		handleResize()

		return () => window.removeEventListener('resize', handleResize)

	}, [])

	useEffect(() => {
		if (screenSize <= 900) {
			setIsMobile(true)
		} else {
			setIsMobile(false)
		}
	}, [screenSize])

	useEffect(() => {
		const handleScroll = () => setScrollY(window.scrollY)

		window.addEventListener('scroll', handleScroll)

		handleScroll()


		return () => window.removeEventListener('scroll', handleScroll)
	})

	useEffect(() => {
		if (scrollY > 50) {
			setIsScrolled(true)
		} else {
			setIsScrolled(false)
		}
	}, [scrollY])

	useEffect(() => {
		if (location && location.pathname === '/contact') {
			setIsScrolled(true);
		} else if (scrollY < 50){
			setIsScrolled(false);
		}
	}, [location, scrollY])

	return (
		<StateContext.Provider value={{
			isActive,
			setIsActive,
			activeMenu,
			setActiveMenu,
			screenSize,
			setScreenSize,
			isMobile,
			setIsMobile,
			replaceWithBr,
			scrollY,
			isScrolled,
			setIsScrolled,
			location,
			setLocation,
			scrollTop,
			offerAnchor,
			setOfferAnchor
		}}>
			{children}
		</StateContext.Provider>
	)
}

export const useStateContext = () => useContext(StateContext)
