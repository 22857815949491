import React from 'react'
import styled from 'styled-components'
import { flex } from "../utils/mixins/flexbox";
import { links } from '../data/links'
import { Link, NavLink } from 'react-router-dom'
import { AiOutlineHome } from "react-icons/ai"
import { MdOutlineCancel } from "react-icons/md"
import { useStateContext } from '../contexts/ContextProvider'

const Sidebar = () => {

	const { activeMenu, setActiveMenu } = useStateContext()

	const activeLink = 'true'
	const normalLink = 'true'


	return (
		<StyledSidebar className={activeMenu ? '' : 'hidden'}>
			<StyledSidebarWrapper>
				{activeMenu &&
					<>
						<StyledSidebarHeader>
							<Link to="/" onClick={() => setActiveMenu(false)}>
								<AiOutlineHome /> Home
							</Link>

							<button type="button" onClick={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}>
								<MdOutlineCancel />
							</button>
						</StyledSidebarHeader>

						<div>
							{links && links.map((item) => (
								<StyledSidebarLinksWrapper key={item.title}>
									<p>{item.title}</p>
									{item.links && item.links.map((link) => (
										<NavLink
											to={`/${link.name}`}
											key={link.name}
											className={({ isActive }) => isActive ? activeLink : normalLink}
											onClick={() => setActiveMenu(false)}
										>
											{link.icon}
											<span>{link.name}</span>

										</NavLink>
									))}
								</StyledSidebarLinksWrapper>
							))}
						</div>
					</>
				}

			</StyledSidebarWrapper>
		</StyledSidebar>
	)
}

export default Sidebar

const StyledSidebar = styled.div`
	font-family: "Montserrat-Medium";
	font-size: 14px;
	line-height: 16px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vW;
	height: 100vh;
	box-shadow: 0 5px 20px 8px rgba(0, 0, 0, 0.1);
	transition: 0.25s;
	padding: 16px;
	z-index: 50;
	background-color: rgba(37, 36, 43, 0.87);
	/* opacity: 0.9; */
	backdrop-filter: blur(10px);
	&.hidden {
		width: 0;
		padding: 0;
	}
	a {
		display: flex;
		align-items: center;
		margin: 5px 0;
		font-size: 16px;
		text-transform: capitalize;
		background-color: transparent;
		color: #fff;
		&:hover {
			color: blue;
			background-color: rgba(0, 0, 0, 0.05);
		}
	}
	p {
		margin-top: 10px;
		margin-bottom: 10px;
		font-size: 16px;
		text-transform: uppercase;
		color: rgba(255, 255, 255, 0.5)
	}
	svg {
		font-size: 24px;
		margin-right: 10px;
		fill: #fff;
	}
`

const StyledSidebarWrapper = styled.div`
	${flex('column', 'flex-start', 'flex-start')};
`

const StyledSidebarHeader = styled.div`
	${flex('row', 'center', 'space-between')};
	width: 100%;
	position: relative;
	margin-bottom: 10px;
	font-size: 22px;
	button {
		background-color: transparent;
	}
	svg {
		font-size: 30px;
	}
`

const StyledSidebarLinksWrapper = styled.div`
	${flex('column', 'flex-start', 'flex-start')};
	margin-bottom: 10px;
`