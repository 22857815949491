import React from 'react'

const SlideDownButton = ({ name, anchor }) => {
	return (
		<button className='main-button slide-down'>
			<a href={`#${anchor}`}>
				{name}
			</a>
		</button>
	)
}

export default SlideDownButton
