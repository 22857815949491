import React from 'react'
import styled from "styled-components";
import { flex } from "../../utils/mixins/flexbox";
import { work } from '../../assets'
import { useStateContext } from '../../contexts/ContextProvider'

const AboutHeader = () => {
	const { isMobile } = useStateContext()

	return (
		<StyledAboutHeader isMobile={isMobile}>
			<StyledAboutHeaderWrapper isMobile={isMobile}>
				<StyledAboutHEaderColumnLeft isMobile={isMobile}>
					<StyledAboutHeaderHeader isMobile={isMobile}>About Us</StyledAboutHeaderHeader>
					<StyledAboutHeaderParagraph>Our company specializes in industrial automation services, with a focus on simulation, robot programming, and PLC programming. In addition to our expertise in these areas, we provide skilled mechanics and electricians to offer comprehensive support to our clients. Our goal is to deliver tailored solutions that enhance efficiency in automation processes, catering to the specific needs of diverse industries.</StyledAboutHeaderParagraph>
				</StyledAboutHEaderColumnLeft>
				<StyledAboutHEaderColumnRight isMobile={isMobile}>
					<picture>
						<img src={work} alt="" />
					</picture>
				</StyledAboutHEaderColumnRight>
			</StyledAboutHeaderWrapper>
		</StyledAboutHeader>
	)
}

export default AboutHeader

const StyledAboutHeader = styled.section`
	margin-bottom: 40px;
	padding: 0 ${props => props.isMobile ? 10 : 120}px;
`

const StyledAboutHeaderWrapper = styled.div`
	${flex('row', 'center', 'space-between')};
	flex-direction: ${props => props.isMobile ? 'column' : 'row'};
	position: relative;
`

const StyledAboutHeaderHeader = styled.h2`
	font-family: "Montserrat-Bold";
	font-size: 35px;
	line-height: 43px;
	margin-bottom: ${props => props.isMobile ? 20 : 40}px;
`

const StyledAboutHeaderParagraph = styled.p`
	font-family: "Montserrat-Medium";
	font-size: 18px;
	line-height: 24px;
`

const StyledAboutHEaderColumnLeft = styled.div`
	position: relative;
	${flex('column', 'flex-start', 'center')};
	flex-direction: column;
	order: ${props => props.isMobile ? 2 : 1};
	width: ${props => props.isMobile ? 100 : 40}%;
	padding-right: ${props => props.isMobile ? 0 : '40px'};
`

const StyledAboutHEaderColumnRight = styled.div`
	order: ${props => props.isMobile ? 1 : 2};
	position: relative;
	width: ${props => props.isMobile ? 100 : 60}%;
	margin-bottom: ${props => props.isMobile ? '20px' : 'unset'};
	picture {
		img {
			object-fit: cover;
			width: 100%;
		}
	}
`